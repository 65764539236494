.main {
    width: 100%;
    height: 100vh;
    background-image: url(./image/IMG_8071\ \(1\).jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.main__text {
    font-family: wremena;
    color: #F00;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    margin: 0;
    padding-top: 30px;
}

.main__par {
    color: #000;
    font-family: wremena;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    margin: 0;
}

.main__promo {
    color: #FFF;
    text-align: center;
    font-family: wremena;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    text-decoration-line: underline;
    margin: 0;
    opacity: 0.999;
    z-index: 10;
}

.main__promo {
    position: relative;
}

.main__promo-video {
    position: absolute;
    top: -107px;
    left: -180px;
    z-index: -2;
    width: 440px;
    height: 248px;
    transition: opacity 0.5s ease;
}

.main__promo-video {
    transition: opacity 0.5s ease;
    opacity: 0;
}

.main__promo-video.show {
    opacity: 1;
}

.main__promo:hover {
    cursor: pointer;
}

.main__promo-container {
    text-align: center;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-top: 45vh;
    z-index: 10;
}

.main__promo-frame {
    position: absolute;
    top: -72px;
    left: -60px;
    right: auto;
    z-index: -1;
}
img.main__promo-frame{
    pointer-events: none;
}

.main__socials {
    padding: 0;
    position: fixed;
    bottom: 30px;
    left: 30px;
    margin: 0;
}

.main__socials-link {
    list-style-type: none;
    margin-bottom: 7px;
}

.main__socials-lin-text {
    color: #FFF;
    font-family: Wremena;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    text-decoration-line: underline;

}

.main__contact {
    padding: 0;
    position: fixed;
    bottom: 30px;
    right: 30px;
    margin: 0;
    text-align: right;
}

.main__contact-tel {
    color: #FFF;
    text-align: right;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Wremena;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    text-decoration-line: underline;
}

.main__contact-lin-text {
    color: #FFF;
    text-align: right;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Wremena;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    text-decoration-line: none;
}

.main__socials-link:last-child {
    margin: 0;
}

@media (max-width: 430px) {
    .main__par{
        margin-left: 15px;
    }
    .main__text{
        margin-left: 15px;
    }
    .main__promo-video {
        width: 0;
        height: 0;
    }

    .main__text {
        font-size: 13px;
    }

    .main__par {
        font-size: 13px;
    }

    .main__promo {
        font-size: 13px;
    }

    .main__socials-lin-text {
        font-size: 13px;
    }

    .main__contact-tel {
        font-size: 13px;
    }

    .main__contact-lin-text {
        font-size: 13px;
    }

    .main__promo-frame {
        left: -77px;
    }

    .main__promo-video.show {
        opacity: 0;
    }
}