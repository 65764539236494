@import url(./vendor/fonts.css);
@import url(./vendor/normalize.css);

.page {
    max-width: 100%;
    min-width: 320px;
    background-color: black;
    margin: 0 auto;
    font-family: 'inter', Arial, sans-serif, wremena;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    padding: 0;
    height: 100vh;
    overflow: hidden;
}